import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { LoginGuard } from './guard/login.guard';

const routes: Routes = [

  { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Home'
  },
 },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [LoginGuard] },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule), canActivate: [LoginGuard]},
  { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule), canActivate: [LoginGuard] },
  {path: 'assesment/:token', loadChildren: () => import('./assesment/assesment.module').then(m => m.AssesmentModule)},
  {path: 'assesment/:token/:sec_id', loadChildren: () => import('./assesment/assesment.module').then(m => m.AssesmentModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  useHash: true,
    scrollPositionRestoration: "enabled",
    scrollOffset: [0, 0],
    anchorScrolling: "enabled",
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
